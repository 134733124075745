import loader from '../common/googleLoader';
import { UserLocation } from '../types';

declare const GOOGLE_API_KEY: string;

export function getLocation(
    success: ({}: { address: UserLocation; timezone: string }) => void,
    failure: () => void
) {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            function (position) {
                loader.load().then(() => {
                    const geoCoder = new google.maps.Geocoder(),
                        pos = {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        };

                    geoCoder.geocode(
                        { location: pos },
                        function (results: any, status: string) {
                            if (status === 'OK') {
                                if (results) {
                                    const address = buildAddress(results);
                                    getTimezone(
                                        position.coords.latitude,
                                        position.coords.longitude,
                                        function (json: {
                                            timeZoneId: string;
                                        }) {
                                            success({
                                                address: {
                                                    lat: position.coords
                                                        .latitude,
                                                    lng: position.coords
                                                        .longitude,
                                                    location_raw:
                                                        JSON.stringify(results),
                                                    street1: address.street1,
                                                    city: address.city,
                                                    state: address.state,
                                                    zipcode: address.zipcode,
                                                },
                                                timezone: json.timeZoneId || '',
                                            });
                                        }
                                    );
                                }
                            } else {
                                getLocationByIP(success, failure);
                            }
                        }
                    );
                });
            },
            function () {
                return getLocationByIP(success, failure);
            }
        );
    } else {
        getLocationByIP(success, failure);
    }
}

export function getTimezone(
    lat: number,
    lng: number,
    callback: (data: { timeZoneId: string }) => void
) {
    const xsr = new XMLHttpRequest();
    xsr.open(
        'GET',
        `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=1458000000&key=${GOOGLE_API_KEY}`
    );

    xsr.onload = function () {
        callback(JSON.parse(xsr.responseText));
    };

    xsr.send();
}

function buildAddress(results: google.maps.GeocoderResult[]) {
    if (results.length) {
        return getAddressFromPlace(results[0]);
    }
    return { city: '', state: '', zipcode: '', street1: '' };
}

export function getAddressFromPlace(
    place: google.maps.GeocoderResult | google.maps.places.PlaceResult
) {
    let street1 = '',
        zipcode = '',
        state = '',
        city = '',
        aal3 = '';

    if (place.address_components)
        for (const c of place.address_components) {
            if (c.types.includes('street_number')) {
                street1 += c.long_name + ' ';
            }

            if (c.types.includes('route')) {
                street1 += c.long_name;
            }

            if (c.types.includes('postal_code') && zipcode === '') {
                zipcode = c.long_name;
            }

            if (
                c.types.includes('administrative_area_level_1') &&
                state === ''
            ) {
                state = c.short_name;
            }

            if (c.types.includes('locality') && city === '') {
                city = c.long_name;
            }

            if (
                c.types.includes('administrative_area_level_3') &&
                aal3 === ''
            ) {
                aal3 = c.long_name;
            }
        }

    return {
        street1,
        city: city || aal3,
        state,
        zipcode,
        lat: place.geometry?.location?.lat(),
        lng: place.geometry?.location?.lng(),
        location_raw: JSON.stringify(place),
    };
}

function getLocationByIP(success: (data: any) => void, failure: () => void) {
    $.ajax({
        url: 'https://geolocation-db.com/jsonp',
        dataType: 'jsonp',
        jsonpCallback: 'callback',
        success: (location) => {
            getTimezone(
                location.latitude,
                location.longitude,
                (json: { timeZoneId: string }) => {
                    success({
                        address: {
                            lat: location.latitude,
                            lng: location.longitude,
                            location_raw: '',
                            street1: '',
                            city: location.city,
                            state: location.state,
                            zipcode: location.postal,
                        },
                        timezone: json.timeZoneId || '',
                    });
                }
            );
        },
    });

    failure();
}
