import UserSettings from '../users/UserSettings';
import { hideBackdrop, showBackdrop } from './backdrop';

export default function EligibleDialog(userId: number | null) {
    if (userId) {
        return;
    }

    const settings = new UserSettings({ userId });

    settings.get(settings.settingName_Eligible, (eligible: boolean) => {
        if (eligible) {
            return;
        }

        if (
            /bot|google|baidu|bing|msn|duckduckbot|teoma|slurp|yandex|crawler|spider|robot|crawling/i.test(
                navigator.userAgent
            )
        ) {
            return;
        }

        const dialog = document.getElementById('eligible-dialog');
        const checkbox = dialog?.querySelector('input');
        const button = dialog?.querySelector('button');

        if (dialog) dialog.style.display = 'initial';
        showBackdrop();
        if (checkbox) checkbox.checked = false;
        checkbox?.addEventListener('change', () => {
            if (button) button.disabled = !checkbox.checked;
        });

        button?.addEventListener('click', () => {
            settings.update(settings.settingName_Eligible, true);
            if (dialog) dialog.style.display = 'none';
            hideBackdrop();
        });
    });
}
