import { Loader } from '@googlemaps/js-api-loader';

declare const GOOGLE_API_KEY: string;

const loader = new Loader({
    apiKey: GOOGLE_API_KEY,
    libraries: ['places'],
});

export default loader;
