import Cookies from 'js-cookie';

type UserId = number | null;
type SettingName = string;
type SettingValue = any;

interface Engine {
    getAll: (userId: UserId, success: any, error: any) => void;
    update: (
        userId: UserId,
        settingName: SettingName,
        settingValue: SettingValue,
        success: any,
        error?: any
    ) => void;
    remove?: (userId: UserId, settingName: SettingName, success: any) => void;
}

class UserSettings {
    userId;
    engine;
    settingName_SearchFilter;
    settingName_WizardSearch;
    settingName_Eligible;

    constructor({ userId }: { userId: UserId }) {
        this.userId = userId;
        if (userId) {
            this.engine = UserSettingsRemote;
        } else {
            this.engine = UserSettingsLocal;
        }

        this.settingName_SearchFilter = 'search_results_filter';
        this.settingName_WizardSearch = 'search_strain_wizard';
        this.settingName_Eligible = 'eligible';
    }

    get(settingName: SettingName, success: any, error?: any) {
        function wrappedCallback(data: any[]) {
            let settingValue;
            if (data) {
                const setting = data.find(
                    (e) => e.setting_name === settingName
                );
                if (setting) {
                    settingValue = setting.setting_value;
                }
            } else {
                settingValue = success();
            }
            success(settingValue);
        }

        this.engine.getAll(this.userId, wrappedCallback, error);
    }

    update(
        settingName: SettingName,
        settingValue: SettingValue,
        success?: any,
        error?: any
    ) {
        this.engine.update(
            this.userId,
            settingName,
            settingValue,
            success,
            error
        );
    }

    remove(settingName: SettingName, success?: any) {
        this.engine.remove?.(this.userId, settingName, success);
    }
}

export default UserSettings;

const UserSettingsLocal: Engine = (function () {
    return {
        settingsCookie: 'user_settings',

        getAll: function getAll(userId: UserId, success: any) {
            let settings = Cookies.get(this.settingsCookie);
            if (settings) {
                settings = JSON.parse(settings);
            }
            success(settings);
        },

        update: function update(
            userId: UserId,
            settingName: SettingName,
            settingValue: SettingValue,
            success: any
        ) {
            const settingsCookie = this.settingsCookie;

            function wrappedCallback(settings: any[]) {
                settings = settings || [];

                const setting = settings.find(
                    (e) => e.setting_name === settingName
                );
                if (setting) {
                    setting.setting_value = settingValue;
                } else {
                    settings.push({
                        setting_name: settingName,
                        setting_value: settingValue,
                    });
                }

                Cookies.set(settingsCookie, JSON.stringify(settings));

                if (success) {
                    success();
                }
            }

            this.getAll(userId, wrappedCallback);
        },

        remove: function (
            userId: UserId,
            settingName: SettingName,
            success: any
        ) {
            const settingsCookie = this.settingsCookie;

            function wrappedCallback(settings: any[]) {
                settings = settings || [];

                settings = settings.filter(
                    (elem) => elem.setting_name !== settingName
                );

                Cookies.set(settingsCookie, JSON.stringify(settings));

                if (success) {
                    success();
                }
            }

            this.getAll(userId, wrappedCallback);
        },
    };
})();

const UserSettingsRemote: Engine = (function () {
    return {
        getAll: function getAll(userId: UserId, success: any, error: any) {
            $.ajax({
                method: 'GET',
                url: `/api/v1/users/${userId}/settings`,
                success: success,
                error: (err) => {
                    console.log(
                        `Cannot get a settings for user: ${error.responseText}`
                    );

                    if (error) {
                        error(err);
                    }
                },
            });
        },

        update: function update(
            userId: UserId,
            settingName: SettingName,
            settingValue: SettingValue,
            success: any,
            error: any
        ) {
            $.ajax({
                method: 'POST',
                url: `/api/v1/users/${userId}/settings`,
                dataType: 'json',
                data: JSON.stringify({
                    setting_name: settingName,
                    setting_value: settingValue,
                }),
                success: function () {
                    if (success) {
                        success();
                    }
                },
                error: function (err) {
                    console.log(
                        `Cannot update a setting ${settingName} for user: ${userId}`
                    );

                    if (error) {
                        error(err);
                    }
                },
            });
        },
    };
})();
