const backdrop = document.getElementById('dialog-backdrop');

export function showBackdrop() {
    if (backdrop) {
        backdrop.style.display = 'initial';
    }
}

export function hideBackdrop() {
    if (backdrop) backdrop.style.display = 'none';
}
