import isEmpty from 'lodash/isEmpty';
import template from 'lodash/template';
import Cookies from 'js-cookie';
import StrainLookup from './pages/strain/strain_lookup';
import { PageOptions, UserLocation } from './types';
import { getLocation } from './common/locationUtils';

class Navbar {
    authenticated;
    location;
    userId;

    constructor(options: PageOptions) {
        this.authenticated = options.authenticated;
        this.location = options.location;
        this.userId = options.userId;

        if (
            (this.authenticated && options.locationUpdate) ||
            !this.authenticated
        ) {
            getLocation(
                (location) => {
                    this.location = location.address;
                    this.updateAddress(location.address);
                    this.clickUpdateLocation();
                    document
                        .querySelector('#update-location-href')
                        ?.classList.remove('hidden');
                },
                () =>
                    document
                        .querySelector('.action.location')
                        ?.classList.add('hidden')
            );
        }

        setupMobileMenu();
        setupAuthMenu();
        initStrainLookupField();
    }

    updateAddress(location: UserLocation) {
        const location_parts: string[] = [];
        if (!location) {
            return;
        }

        $('.nav-bar-user-street').text(location.street1 || '');

        location_parts.push(location.city || '');
        location_parts.push(location.state || '');
        location_parts.push(location.zipcode || '');

        if (
            location_parts.length === 0 &&
            location.location_raw &&
            isEmpty(location.location_raw)
        ) {
            const parsed = JSON.parse(location.location_raw);
            if (parsed && parsed[0] && parsed[0].formatted_address) {
                location_parts.push(parsed[0].formatted_address);
            }
        }

        $('.nav-bar-user-location').text(location_parts.join(', '));
        this.saveUserLocation(location);
    }

    clickUpdateLocation() {
        document
            .getElementById('update-location-href')
            ?.addEventListener('click', () => {
                if (this.location) this.saveUserLocation(this.location);
            });
    }

    saveUserLocation(location: UserLocation) {
        const locationRaw = location.location_raw,
            $locationInput = $('.your-location-value');

        if (locationRaw) {
            const parsed = JSON.parse(locationRaw);
            if (parsed && parsed[0] && $locationInput) {
                $locationInput
                    .val(parsed[0].formatted_address)
                    .trigger('change');
            }
        }

        if (this.authenticated) {
            $.ajax({
                method: 'POST',
                url: `/api/v1/users/${this.userId}/geo_locations`,
                data: JSON.stringify({ address: location }),
            });
        } else {
            delete location.location_raw;
            Cookies.set(
                'user_geo_location',
                JSON.stringify({ address: location })
            );
        }
    }
}

function setupMobileMenu() {
    const mobileMenu = document.getElementById('mobile-menu');
    const body = document.body;

    document
        .getElementById('mobile-menu-btn')
        ?.addEventListener('click', () => {
            mobileMenu?.classList.remove('hidden');
            body.classList.add('overflow-hidden');
        });

    document
        .getElementById('mobile-menu-close-btn')
        ?.addEventListener('click', () => {
            mobileMenu?.classList.add('hidden');
            body.classList.remove('overflow-hidden');
        });
}

function setupAuthMenu() {
    const authMenu = document.getElementById('popup-menu-content');

    document.addEventListener('click', (e) => {
        const clickInsideBtn = (e.target as Element).closest('#auth-menu-btn');

        if (clickInsideBtn) {
            authMenu?.classList.toggle('hidden');
        } else {
            authMenu?.classList.add('hidden');
        }
    });
}

function initStrainLookupField() {
    const lookupTemplate = template(
        document.getElementById('strain-lookup-field')?.innerHTML
    );

    const fieldEl = document.querySelector('.navbar .strain-name-field');
    if (fieldEl)
        fieldEl.innerHTML = lookupTemplate({
            lookup_placeholder: 'Look up a strain',
        });

    const mobileInputEl = document.querySelector<HTMLInputElement>(
        '#mobile-menu .strain-lookup-input'
    );

    if (mobileInputEl) {
        new StrainLookup({
            inputEl: mobileInputEl,
            onSelect: navigateToStrainDetailPage,
        });
    }

    const inputEl = document.querySelector<HTMLInputElement>(
        '#navbar-strain-name-field .strain-lookup-input'
    );

    if (inputEl) {
        new StrainLookup({
            inputEl,
            onSelect: navigateToStrainDetailPage,
        });
    }
}

function navigateToStrainDetailPage(selected: {
    variety: string;
    slug: string;
}) {
    if (selected.variety && selected.slug) {
        window.location.href = `/strains/${selected.variety}/${selected.slug}/`;
    }
}

export default Navbar;
